import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { ReactComponent as TextSvg } from '../images/canvaTextAlert.svg'
import { useLocation } from 'react-router-dom'

const Title = () => {
  const location = useLocation()

  useEffect(() => {
    if (location.pathname === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [location])

  const scrollToTheDetails = () => {
    const element = document.getElementById('theDetailsId')
    if (element) {
      element.scrollIntoView()
    }
  }

  return (
    <>
      <Container className=''>
        <Row className='py-4'>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <h1 className='display-4' style={{ textAlign: 'center' }}>
              Don't wait months for a Global Entry appointment!
            </h1>
            <h5 style={{ textAlign: 'center', color: '#97989a' }}>
              Sign up to receive text message alerts the moment an appointment
              becomes available.
            </h5>
            <div style={{ textAlign: 'center', margin: '4%' }}>
              <button
                type='button'
                onClick={scrollToTheDetails}
                className='btn btn-info btn-lg'
              >
                Sign Up!
              </button>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextSvg width='auto' height='auto' />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Title
