import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import Home from './pages/Home'
import Attributes from './pages/Attributes'
import SignUp from './pages/SignUp'
import VerifyPhoneNumber2 from './pages/verifyPhoneNumber2'
import Payment from './pages/Payment'
import Error from './pages/Error'
import PaymentSuccess from './pages/PaymentSuccess'
import MoneyBack from './pages/MoneyBack'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsAndConditions from './pages/TermsAndConditions'
import Qr from './pages/Qr'

// import Admin from './pages/Admin'

const App = () => {
  return (
    <div className='App'>
      <BrowserRouter>
        <Header />
        <main>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/attributes' element={<Attributes />} />
            <Route path='/signUp' element={<SignUp />} />
            <Route
              path='/verifyPhoneNumber2'
              element={<VerifyPhoneNumber2 />}
            />
            <Route path='/error' element={<Error />} />
            <Route path='/payment' element={<Payment />} />
            <Route path='/paymentSuccess' element={<PaymentSuccess />} />
            <Route path='/moneyBack' element={<MoneyBack />} />
            <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
            <Route
              path='/termsAndConditions'
              element={<TermsAndConditions />}
            />
            <Route path='/qr' element={<Qr />} />
            {/* <Route path='/admin352257' element={<Admin />} /> */}
          </Routes>
        </main>

        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App
