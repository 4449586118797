import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer
      style={{
        backgroundColor: '#dbd9d9',
      }}
    >
      <Container>
        <Row className='justify-content-center'>
          <Col className='text-center mb-1 mt-2'>
            Copyright &copy; TJW Innovations LLC
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col className='text-center col-md-3 mb-1' style={{ width: 'auto' }}>
            <Link to='/attributes'>Attributes</Link>
          </Col>
          <Col className='text-center col-md-3 mb-1' style={{ width: 'auto' }}>
            <Link to='/privacyPolicy'>Privacy Policy</Link>
          </Col>
          <Col className='text-center col-md-3 mb-1' style={{ width: 'auto' }}>
            <Link to='/termsAndConditions'>Terms & Conditions</Link>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col className='text-center col-md-3 mb-0' style={{ width: 'auto' }}>
            <p>
              Contact us at{' '}
              <a href='mailto:support@globalentrytextalerts.com'>
                support@globalentrytextalerts.com
              </a>
            </p>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col
            className='text-center col-md-3 mb-1 mt-0'
            style={{ width: 'auto', fontSize: '75%' }}
          >
            <p>
              Global Entry Text Alerts is an independent service and is not
              affiliated with any government entity.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
