import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { ReactComponent as SignUpSvg } from '../images/signUp.svg'
import { ReactComponent as ReceiveTextSvg } from '../images/receiveText.svg'
import { ReactComponent as CalendarSvg } from '../images/calendar.svg'

const HowItWorks = () => {
  return (
    <>
      <Container
        className='mt-3'
        style={{ flexWrap: 'wrap', marginBottom: '4rem' }}
      >
        <h2 className='text-center'>Here is how it works:</h2>
        <Row>
          <Col
            sm={4}
            md={4}
            lg={4}
            className='it-works-style text-center align-items-center'
          >
            <SignUpSvg className='it-works-svg-style' />
            <h3>Step 1</h3>
            <p>
              Sign up and select up to 3 Global Entry enrollment locations.
              We'll scan 24/7 for an appointment to become available.
            </p>
          </Col>
          <Col
            sm={4}
            md={4}
            lg={4}
            className='it-works-style text-center align-items-center'
          >
            <ReceiveTextSvg className='it-works-svg-style' />
            <h3>Step 2</h3>
            <p>
              Receive a text message immediately when an appointment becomes
              available at any of your chosen locations.
            </p>
          </Col>
          <Col
            sm={4}
            md={4}
            lg={4}
            className='it-works-style text-center align-items-center'
          >
            <CalendarSvg className='it-works-svg-style' />
            <h3>Step 3</h3>
            <p>
              <b>Be quick!</b> Click the link in the text message, log into your
              Global Entry account and claim the open appointment.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default HowItWorks
