import { Link } from 'react-router-dom'

const TESTING_URL = 'http://localhost:4000'
const PRODUCTION_URL = 'https://globalentrytextalerts.com'
export const URL = PRODUCTION_URL

export const accordionData = [
  {
    title: 'How does Global Entry Text Alerts work?',
    content: `Each day, individuals cancel or reschedule their appointments, creating openings for others. 
    We scan 24/7 to detect any available appointments at the locations you choose. Immediately when an 
    appointment becomes available at one of your locations, we'll send you a text message so you 
    can be the first to claim the open appointment.`,
  },
  {
    title: 'How much sooner will I find an appointment?',
    content: `Most users are able to find an appointment or secure a better appointment within a week of signing up!`,
  },
  {
    title: 'Is this service a subscription or a recurring payment?',
    content: `No, Global Entry Text Alerts has a one-time cost of $25 to receive text message alerts for 30 days.
    It is not a subscription, and you will NOT be billed again.`,
  },
  {
    title:
      'Can I cancel this service and stop receiving Global Entry Text Alerts?',
    content: `Yes, you can cancel at any time by replying "STOP"`,
  },
  {
    title: 'Is my payment secure?',
    content: (
      <span>
        Absolutely! We prioritize the security of your payment information. We
        utilize{' '}
        <Link to='https://squareup.com/us/en?v=all' target='_blank'>
          Square
        </Link>
        {', '}a trusted and reputable payment processing platform, to handle all
        transactions. You can have peace of mind knowing that your payment
        details are handled securely.
      </span>
    ),
  },
  {
    title: 'What is the refund policy?',
    content: (
      <span>
        We have a{' '}
        <Link to='/moneyBack' target='_blank'>
          100% Money Back Guarantee
        </Link>{' '}
        policy to ensure your peace of mind. If you're not satisfied with our
        service, contact us within 30 days for a full refund, no questions
        asked. We stand behind our service and want you to be happy with your
        purchase.
      </span>
    ),
  },
]
