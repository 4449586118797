import { useEffect, useState, useRef } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { URL } from '../constants'

const PaymentSuccess = () => {
  // const [token, setToken] = useState(null)
  const location = useLocation()
  const [error, setError] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [data, setData] = useState({})
  const [locationData, setLocationData] = useState([])
  const tokenRef = useRef(null)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    // // Get the token from the URL parameters
    // const searchParams = new URLSearchParams(window.location.search)
    // const token = searchParams.get('data')
    // setToken(token)
    setLoaded(true)

    const fetchData = async () => {
      // Obtains the transaction of the tokenized userId
      let response
      try {
        response = await fetch(`${URL}/api/protected/userReceipt`, {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${tokenRef.current}`,
          },
        })
      } catch (error) {
        setError(
          'There was an issue on our end. Please try again later and contact support if the error persists.'
        )
      }
      let json = await response.json()

      if (!response.ok) {
        setError(json.error)
      } else {
        setError(null)
        setData(json['mostRecentPayment'])
      }

      response = null
      json = null
      try {
        response = await fetch(`${URL}/api/protected/userLocations`, {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${tokenRef.current}`,
          },
        })
      } catch (error) {
        setError(
          'There was an issue on our end. Please try again later and contact support if the error persists.'
        )
      }
      json = await response.json()

      if (!response.ok) {
        setError(json.error)
      } else {
        setError(null)
        setLocationData(json['locations'])
      }
    }

    if (location.state == null || location.state.token == null) {
      setError('Please go to the sign up page to use this service.')
    } else {
      try {
        tokenRef.current = location.state.token
      } catch (error) {
        setError(
          'There was a problem finding this user. Please try again. If the problem persists, contact support.'
        )
      }
    }

    if (location.state != null) {
      fetchData()
    }
  }, [location.state])

  if (!loaded) {
    return null
  }

  return (
    <>
      <Container className='justify-content-center mt-5'>
        {error && (
          <div className='card text-white bg-danger mb-3'>
            <div className='card-body'>
              <h4 className='card-title'>Oh no!</h4>
              <p className='card-text'>{error}</p>
            </div>
          </div>
        )}
        <h1 style={{ textAlign: 'center', paddingBottom: '1rem' }}>
          {' '}
          <i className='fa-regular fa-circle-check fa-xl'></i> You'll receive a
          text anytime a Global Entry appointment opens up
        </h1>
        <Row className='justify-content-center mt-4'>
          <Col md={6}>
            <Card style={{ borderWidth: 'medium', zIndex: '1' }}>
              <Card.Header>
                <h3>Payment details:</h3>
              </Card.Header>
              <Card.Body>
                <p>
                  <strong>Confirmation #:</strong> {data.confirmationId}
                </p>
                <p>
                  <strong>Receipt Link: </strong>
                  <Card.Link href={data.receiptUrl} target='_blank'>
                    {data.receiptUrl}
                  </Card.Link>
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <p style={{ textAlign: 'center', paddingTop: '1rem' }}>
          If you've found an appointment, or you do not want to receive text
          messages anymore, please reply "STOP"
        </p>
        <div style={{ textAlign: 'center' }}>
          <Link to='/'>
            <button
              type='button'
              className='btn btn-info btn-md'
              style={{ marginTop: '1rem', alignItems: 'center' }}
            >
              Return Home
            </button>
          </Link>
        </div>
      </Container>
    </>
  )
}

export default PaymentSuccess
