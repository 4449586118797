import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { URL } from '../constants'

const Qr = () => {
  const [qr, setQr] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    // Get the query string from the URL
    const queryString = window.location.search

    // Parse the query string to extract parameters
    const urlParams = new URLSearchParams(queryString)

    // Get the value of the "loc" parameter
    const locParam = urlParams.get('loc')

    // Set the value of the "qr" state variable
    setQr(locParam)

    if (qr !== '') {
      // POST request to qr backend. *** Does not wait on a response ***
      fetch(`${URL}/api/unprotected/qr`, {
        method: 'POST',
        body: JSON.stringify({ locParam: qr }),
        headers: {
          'Content-Type': 'application/json',
        },
      })

      // Navigate to the home page
      navigate('/')
    }
  }, [navigate, qr])

  return (
    <>
      <Container className='py-4'>
        <h1>Redirecting you to the home page!</h1>
      </Container>
    </>
  )
}

export default Qr
