import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Accordion from './Accordion'
import { accordionData } from '../constants'

const WhatYouGet = () => {
  return (
    <>
      <Container>
        <Row className='justify-content-center mb-3'>
          <Col
            className='col-md-10 my-auto'
            id='theDetailsId'
            style={{ scrollMarginTop: '12vh' }}
          >
            <h1 style={{ textAlign: 'center' }}>
              <i className='fa-regular fa-credit-card'></i> Sign Up for Only
              $25!
            </h1>
            <h5
              className='large-screen-content'
              style={{ textAlign: 'center' }}
            >
              Tired of waiting months for a Global Entry Appointment? Global
              Entry Text Alerts is designed to help you secure appointments
              quickly and effortlessly. We understand the frustration of limited
              availability and long wait times, which is why we provide
              real-time text message alerts as soon as appointments become
              available. You will be the first to know. Say goodbye to endless
              waiting and say hello to streamlined travel.
            </h5>

            <h5
              className='small-screen-content'
              style={{ textAlign: 'center' }}
            >
              Be the first to know when an appointment becomes available. Say
              goodbye to endless waiting and hello to streamlined travel.
            </h5>
          </Col>
        </Row>
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Link to='/signUp'>
            <button
              type='button'
              className='btn btn-info btn-lg'
              style={{ fontSize: '1.5rem', padding: '8px 16px' }}
            >
              Sign Up!
            </button>
          </Link>
        </div>
        <Row className='justify-content-center'>
          <Col className='col-md-10 my-auto'>
            <div className='card mb-3' style={{ zIndex: '1' }}>
              <h4 className='card-header'>The details:</h4>

              <ul className='list-group list-group-flush'>
                <li className='list-group-item'>
                  Select Up to 3 Enrollment Locations of Your Choice
                </li>
                <li className='list-group-item'>
                  Receive Up to 30 Text Message Alerts per Day
                </li>
                <li className='list-group-item'>
                  Enjoy the Alerts for 30 Days
                </li>
                <li className='list-group-item'>Pay only $25</li>
                <li className='list-group-item'>
                  One-time Payment: No Subscription, No Recurring Payments
                </li>
                <li className='list-group-item'>
                  Our Promise:{' '}
                  <Link to='/moneyBack' target='_blank'>
                    100% Money Back Guarantee
                  </Link>{' '}
                  for Your Peace of Mind
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>

      <Container style={{ marginTop: '2rem', marginBottom: '3rem' }}>
        <Row className='justify-content-center mb-4'>
          <Col className='col-md-10 my-auto'>
            <div>
              <h2 style={{ textAlign: 'center' }}>FAQ</h2>
              <div className='accordion'>
                {accordionData.map(({ title, content }) => (
                  <Accordion title={title} content={content} />
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default WhatYouGet
