import { useEffect } from 'react'
import { ReactComponent as ErrorSvg } from '../images/error.svg'
import { Container } from 'react-bootstrap'

const Error = () => {
  // Used to scroll back to the top anytime Home is called
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Container style={{ width: '50%' }}>
        <div className='card text-white bg-danger mb-3'>
          <div className='card-body'>
            <h4 className='card-title'>Oh no!</h4>
            <p className='card-text'>
              Please contact support if you need assistance.
            </p>
          </div>
        </div>
        <ErrorSvg />
      </Container>
    </>
  )
}

export default Error
