import { useEffect, useState, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { URL } from '../constants'

const VerifyPhoneNumber2 = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [verificationCode, setVerificationCode] = useState('')
  const [error, setError] = useState(null)
  const [attemptsLeft, setAttemptsLeft] = useState(null)
  const [generateCodeClicked, setGenerateCodeClicked] = useState(false)
  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(true)
  const tokenRef = useRef(null)
  const [sendCodeDisabled, setSendCodeDisabled] = useState(true)

  useEffect(() => {
    if (location.state == null || location.state.token == null) {
      setError('Please go to the sign up page to use this service.')
    } else {
      try {
        tokenRef.current = location.state.token
        setSendCodeDisabled(false)
      } catch (error) {
        setError(
          'There was a problem finding this user. Please try again. If the problem persists, contact support.'
        )
      }
    }
  }, [location.state])

  // Used to scroll back to the top anytime Home is called
  useEffect(() => {
    window.scrollTo(0, 0)

    // Sets a 30 second timer
    const timer = setTimeout(() => {
      setIsResendButtonDisabled(false)
    }, 30000)
    return () => clearTimeout(timer)
  }, [])

  // Used to send a new code
  const sendAlertAndNewCode = async () => {
    alert('Another code is being sent.')
    setIsResendButtonDisabled(true)
    generateCode()
  }

  // Generates a code by calling backend
  const generateCode = async () => {
    let response
    try {
      response = await fetch(`${URL}/api/protected/verificationCode`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokenRef.current}`,
        },
      })
    } catch (error) {
      setError(
        'There was an issue on our end. Please try again later and if the error persist, contact support.'
      )
    }
    const json = await response.json()

    if (!response.ok) {
      setError(json.error)
      // Send them to the signUp page
      if (json.error === 'No user was given') {
        // window.location.href = '/signUp'
      }
    }

    if (response.ok) {
      setError(null)
      setVerificationCode('')
      setAttemptsLeft(11 - json.codesSent)
    }

    setGenerateCodeClicked(true)
  }

  // Checks the format of the verification code
  const verificationFormat = (verificationCode) => {
    if (/[^0-9]/.test(verificationCode) || verificationCode.length > 6) {
      return false
    }
    return true
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    // Check the verification code
    let response
    try {
      response = await fetch(`${URL}/api/protected/verificationCode`, {
        method: 'PATCH',
        body: JSON.stringify({
          // userId: tokenRef.current,
          verificationCode: verificationCode,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokenRef.current}`,
        },
      })
    } catch (error) {
      setError(
        'There was an issue on our part. Please try again later and conact suppor if the error persists.'
      )
    }
    const json = await response.json()

    if (!response.ok) {
      setError(json.error)
    }

    if (response.ok) {
      setError('')
      setVerificationCode('')
      setSendCodeDisabled(true)

      navigate('/payment', {
        state: { token: json['token'] },
      })
    }

    return
  }

  return (
    <>
      {/* First screen forces user to click button to send a code. Then after they clicked the 
      button, another screen renders for them to input the code. */}
      {generateCodeClicked ? (
        <Container className='py-4 w-75' style={{ textAlign: 'center' }}>
          <h2>Phone Number Verification:</h2>
          <p className='text-secondary'>
            We sent you a text message with a 6-digit code to verify your phone
            number. Please input the code below.
          </p>

          {error && (
            <div className='card text-white bg-danger mb-3'>
              <div className='card-body'>
                <h4 className='card-title'>Oh no!</h4>
                <p className='card-text'>{error}</p>
              </div>
            </div>
          )}
          {attemptsLeft !== null && attemptsLeft === 1 && (
            <div className='card text-white bg-danger mb-3'>
              <div className='card-body'>
                <h4 className='card-title'>Alert:</h4>
                <p className='card-text'>
                  This is your final attempt at the verification code. If you
                  believe this is an error, please contatc support.
                </p>
              </div>
            </div>
          )}

          <Container className='py-4 w-50'>
            <form style={{ transform: 'scale(1.25)' }} onSubmit={handleSubmit}>
              <fieldset>
                <input
                  type='tel'
                  className={`form-control ${
                    verificationFormat(verificationCode) ? '' : 'is-invalid'
                  }`}
                  placeholder='123456'
                  pattern='\d{6}'
                  onChange={(e) => setVerificationCode(e.target.value)}
                  value={verificationCode}
                  maxLength='6'
                />
                <label className='invalid-feedback'>
                  Only numbers are accepted for the verification code.
                </label>
              </fieldset>
              <button
                type='submit'
                className='btn btn-info btn-md'
                style={{ marginTop: '1rem', alignSelf: 'left' }}
              >
                Verify
              </button>
            </form>
          </Container>
          {isResendButtonDisabled ? (
            <p className='text-secondary'>
              Didn't receive the code? After 30 seconds you can{' '}
              <Link href='#' style={{ color: '#95a5a6' }}>
                send another verification code.
              </Link>
            </p>
          ) : (
            <p className='text-secondary'>
              Didn't receive the code? After 30 seconds you can{' '}
              <Link href='#' onClick={sendAlertAndNewCode}>
                send another verification code.
              </Link>
            </p>
          )}
        </Container>
      ) : (
        <Container className='py-4 w-75' style={{ textAlign: 'center' }}>
          {error && (
            <div className='card text-white bg-danger mb-3'>
              <div className='card-body'>
                <h4 className='card-title'>Oh no!</h4>
                <p className='card-text'>{error}</p>
              </div>
            </div>
          )}
          <h2>We need to verify your phone number</h2>
          <p className='text-secondary'>
            We will send you a text message with a 6-digit code. You will be
            prompted to input the code.
          </p>
          <button
            type='button'
            className='btn btn-info btn-md'
            style={{ marginTop: '1rem', alignSelf: 'left' }}
            onClick={generateCode}
            disabled={sendCodeDisabled}
          >
            Send me the code
          </button>
        </Container>
      )}
    </>
  )
}

export default VerifyPhoneNumber2
