import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { ReactComponent as QueueSvg } from '../images/queue.svg'
import { ReactComponent as RunningManSvg } from '../images/runningMan.svg'
import { ReactComponent as CancelledSvg } from '../images/cancelled.svg'

const Roadmap = () => {
  return (
    <>
      <Container
        style={{ flexWrap: 'wrap', textAlign: 'center', marginBottom: '2rem' }}
      >
        <Row>
          <Col className='my-auto'>
            <h3>Wait times for a Global Entry appointment are long...</h3>
            <h6>
              Many enrollment centers have no appointments available. At other
              locations, you could wait months for an open appointment.
            </h6>
          </Col>
          <Col className='my-auto' style={{ zIndex: '-1' }}>
            <QueueSvg className='road-map-svg-style' />
          </Col>
        </Row>
        <Row>
          <Col className='my-auto' style={{ zIndex: '-1' }}>
            <CancelledSvg className='road-map-svg-style' />
          </Col>
          <Col className='my-auto'>
            <h3>Every day people cancel their appointments.</h3>
            <h6>
              You can be the first to grab it. For example, if someone cancels
              their appointment for tomorrow, we'll alert you immediately and
              you can snag that appointment!
            </h6>
          </Col>
        </Row>
        <Row>
          <Col className='my-auto'>
            <h3>You will have to be fast!</h3>
            <h6>
              When you get a text message that an appointment becomes available,
              you must log in quickly and claim the appointment.
            </h6>
          </Col>
          <Col className='my-auto' style={{ zIndex: '-1' }}>
            <RunningManSvg className='road-map-svg-style' />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Roadmap
