import { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useNavigate, Link } from 'react-router-dom'
import { URL } from '../constants'

const SignUp = () => {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [phoneNumber, setPhoneNumber] = useState('')
  const [error, setError] = useState(null)
  const [location1, setLocation1] = useState('')
  const [location2, setLocation2] = useState('')
  const [location3, setLocation3] = useState('')
  const [checkboxChecked, setCheckboxChecked] = useState(false)

  useEffect(() => {
    // Obtain the location data for the dropdown menus
    let response
    const fetchData = async () => {
      try {
        response = await fetch(`${URL}/api/unprotected/`)
      } catch (error) {
        setError(
          'There was an error on our part. Please try again later and if the error persist, contact support.'
        )
      }
      const json = await response.json()
      setData(json)
    }
    fetchData()
  }, [])

  // Used to scroll back to the top anytime Home is called
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // Resets the dropdown menus to their defaults
  const resetDropdown = () => {
    var dropDown = document.getElementById('location1')
    dropDown.selectedIndex = 0

    dropDown = document.getElementById('location2')
    dropDown.selectedIndex = 0

    dropDown = document.getElementById('location3')
    dropDown.selectedIndex = 0
  }

  const handleSetLocation1 = (value) => {
    if (value === '') {
      document.getElementById('location2').selectedIndex = 0
      handleSetLocation2('')
    }
    setLocation1(value)
  }

  const handleSetLocation2 = (value) => {
    if (value === '') {
      setLocation3('')
      document.getElementById('location3').selectedIndex = 0
    }

    setLocation2(value)
  }

  // Javascript to add dashes in the phone number input area
  const addDashesInPhoneNumber = (number, event) => {
    if (event.nativeEvent.inputType === 'insertFromPaste') {
      // replace any non-digit characters with empty string
      var npa = '',
        nxx = '',
        last4 = ''
      npa = number.substr(0, 3)
      nxx = number.substr(3, 3)
      last4 = number.substr(6, 4)
      number = npa + '-' + nxx + '-' + last4
    }
    // Adds a "-" to format phone number to be ###-###-####
    else if (
      event.nativeEvent.inputType !== 'deleteContentBackward' &&
      (number.length === 3 || number.length === 7)
    ) {
      number += '-'
    }

    return number
  }

  // Checks if the phone number is formatted properly
  const isPhoneNumberFormatted = (number) => {
    if (/[^0-9-]/.test(number) || number.length > 12) {
      return false
    }
    return true
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    // Check that the user has checked the checkbox
    if (checkboxChecked === false) {
      setError('You must check the checkbox to proceed.')
      return
    }

    let tempLocation1 = location1
    let tempLocation2 = location2
    let tempLocation3 = location3

    // Preparing for the backend that wants 'n/a'
    if (tempLocation2 === '') {
      tempLocation2 = 'n/a'
    }

    // Preparing for the backend that wants 'n/a'
    if (tempLocation3 === '') {
      tempLocation3 = 'n/a'
    }

    const locations = [tempLocation1, tempLocation2, tempLocation3]

    // Creates a user in the database
    let response
    try {
      response = await fetch(`${URL}/api/unprotected/`, {
        method: 'POST',
        body: JSON.stringify({ phoneNumber, locations }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
    } catch (error) {
      setError(
        'There was an error on our part. Please try again later and if the error persist, contact support.'
      )
    }
    const json = await response.json()

    if (!response.ok) {
      setError(json.error)
    }

    if (response.ok) {
      setError(null)
      setPhoneNumber('')
      resetDropdown()

      navigate('/verifyPhoneNumber2', {
        state: { token: json['token'] },
      })
    }
  }

  return (
    <>
      <Container className='py-4' style={{ width: '85%' }}>
        <h2>Sign Up:</h2>
        <p className='text-secondary'>
          Please select a minimum of 1 enrollment locations to receive
          appointment alerts.
        </p>

        {error && (
          <div className='card text-white bg-danger mb-3'>
            <div className='card-body'>
              <h4 className='card-title'>Oh no!</h4>
              <p className='card-text'>{error}</p>
            </div>
          </div>
        )}

        {/* <form onSubmit={handleSubmit}> */}
        <form onSubmit={handleSubmit}>
          <fieldset>
            <div className='form-control'>
              <h5 className='form-label mt-3'>Phone Number</h5>
              <div className='input-group'>
                <span className='input-group-text'>US +1</span>
                <input
                  type='tel'
                  className={`form-control ${
                    isPhoneNumberFormatted(phoneNumber) ? '' : 'is-invalid'
                  }`}
                  id='inputPhoneNumber'
                  placeholder='201-555-5555'
                  onChange={(e) =>
                    setPhoneNumber(addDashesInPhoneNumber(e.target.value, e))
                  }
                  value={phoneNumber}
                  pattern='^\d{3}-\d{3}-\d{4}$'
                />
              </div>
              <p className='text-muted mt-1 mb-1'>
                Only United States-based phone numbers are eligible.
              </p>
              <label className='invalid-feedback mb-2'>
                The phone number format should be ###-###-####.
              </label>

              <h5 className='form-label mt-3'>Enrollment Location 1</h5>
              {/* <select
                className='form-select'
                required
                onChange={(e) => setLocation1(e.target.value)}
                id='location1'
              >
                <option value='' defaultValue>
                  --
                </option>
                {data.map((item) => (
                  <option key={item.name} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </select> */}
              <select
                className='form-select'
                required
                onChange={(e) => handleSetLocation1(e.target.value)}
                id='location1'
              >
                <option value='' defaultValue>
                  --
                </option>

                {(() => {
                  // Group the locations by state
                  const groupedLocations = {}
                  data.forEach((item) => {
                    const state = item.state
                    if (!(state in groupedLocations)) {
                      groupedLocations[state] = []
                    }
                    groupedLocations[state].push(item)
                  })

                  // Sort the states alphabetically
                  const sortedStates = Object.keys(groupedLocations).sort()

                  // Generate the <optgroup> and <option> elements
                  return sortedStates.map((state) => (
                    <optgroup key={state} label={state}>
                      {groupedLocations[state].map(
                        (location) =>
                          !location.disabled && (
                            <option key={location.name} value={location.name}>
                              {location.name}
                            </option>
                          )
                      )}
                    </optgroup>
                  ))
                })()}
              </select>
              <Row xs='auto' className='mt-3'>
                <Col style={{ paddingRight: '0%' }}>
                  <h5 className='form-label'>Enrollment Location 2</h5>
                </Col>
                <Col>
                  <p className='text-secondary' style={{ fontSize: '16px' }}>
                    (Optional)
                  </p>
                </Col>
              </Row>
              <select
                className='form-select'
                onChange={(e) => handleSetLocation2(e.target.value)}
                id='location2'
                disabled={location1 === ''}
              >
                <option value='' defaultValue>
                  -- Additional Location
                </option>
                {(() => {
                  // Group the locations by state
                  const groupedLocations = {}
                  data.forEach((item) => {
                    const state = item.state
                    if (!(state in groupedLocations)) {
                      groupedLocations[state] = []
                    }
                    groupedLocations[state].push(item)
                  })

                  // Sort the states alphabetically
                  const sortedStates = Object.keys(groupedLocations).sort()

                  // Generate the <optgroup> and <option> elements
                  return sortedStates.map((state) => (
                    <optgroup key={state} label={state}>
                      {groupedLocations[state].map(
                        (location) =>
                          !location.disabled && (
                            <option key={location.name} value={location.name}>
                              {location.name}
                            </option>
                          )
                      )}
                    </optgroup>
                  ))
                })()}
              </select>
              <Row xs='auto' className='mt-3'>
                <Col style={{ paddingRight: '0%' }}>
                  <h5 className='form-label'>Enrollment Location 3</h5>
                </Col>
                <Col>
                  <p className='text-secondary' style={{ fontSize: '16px' }}>
                    (Optional)
                  </p>
                </Col>
              </Row>
              <select
                className='form-select mb-4'
                onChange={(e) => setLocation3(e.target.value)}
                id='location3'
                disabled={location2 === ''}
              >
                <option value='' defaultValue>
                  -- Additional Location
                </option>
                {(() => {
                  // Group the locations by state
                  const groupedLocations = {}
                  data.forEach((item) => {
                    const state = item.state
                    if (!(state in groupedLocations)) {
                      groupedLocations[state] = []
                    }
                    groupedLocations[state].push(item)
                  })

                  // Sort the states alphabetically
                  const sortedStates = Object.keys(groupedLocations).sort()

                  // Generate the <optgroup> and <option> elements
                  return sortedStates.map((state) => (
                    <optgroup key={state} label={state}>
                      {groupedLocations[state].map(
                        (location) =>
                          !location.disabled && (
                            <option key={location.name} value={location.name}>
                              {location.name}
                            </option>
                          )
                      )}
                    </optgroup>
                  ))
                })()}
              </select>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  onChange={(event) => setCheckboxChecked(event.target.checked)}
                />
                <label
                  className='form-check-label'
                  style={{ fontSize: '90%', marginBottom: '1%' }}
                >
                  By checking this box, you agree to the{' '}
                  <Link to='/termsAndConditions' target='_blank'>
                    Terms & Conditions
                  </Link>
                  {', '}
                  <Link to='/privacyPolicy' target='_blank'>
                    Privacy Policy
                  </Link>
                  {', '}
                  and to receive SMS text messages from Global Entry Text
                  Alerts.
                </label>
              </div>
            </div>
          </fieldset>
          <br />
          <div style={{ textAlign: 'center' }}>
            <button
              type='submit'
              className='btn btn-info btn-lg'
              style={{ marginTop: '1rem' }}
            >
              Next
            </button>
          </div>
        </form>
      </Container>
    </>
  )
}

export default SignUp
