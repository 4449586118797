import { React, useState, useRef, useEffect } from 'react'
import ReactLoading from 'react-loading'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
  PaymentForm,
  CreditCard,
  ApplePay,
  GooglePay,
} from 'react-square-web-payments-sdk'
import { URL } from '../constants'
import { ReactComponent as VisaSvg } from '../images/visa.svg'
import { ReactComponent as AmexSvg } from '../images/amex.svg'
import { ReactComponent as MastercardSvg } from '../images/mastercard.svg'
import { ReactComponent as DiscoverSvg } from '../images/discover.svg'

const SquarePayment = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [error, setError] = useState(null)
  const [isDisabled, setIsDisabled] = useState(true)
  const [data, setData] = useState([])
  const tokenRef = useRef(null)
  // const [showLoading, setShowLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      // Obtains the locations the tokenized userId is tracking
      let response
      try {
        response = await fetch(`${URL}/api/protected/userLocations`, {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${tokenRef.current}`,
          },
        })
      } catch (error) {
        setError(
          'There was an issue on our end. Please try again later and contact support if the error persists.'
        )
        setIsDisabled(true)
      }
      const json = await response.json()

      if (!response.ok) {
        setData([])
        setError(json.error)
        setIsDisabled(true)
      } else {
        setData(json['locations'])

        if (json['paidStatus'] === true) {
          setError(
            'You have already paid. If you think this was a mistake, please contact support.'
          )
        } else {
          setIsDisabled(false)
        }
      }
    }

    if (location.state == null || location.state.token == null) {
      setError('Please go to the sign up page to use this service.')
    } else {
      try {
        tokenRef.current = location.state.token
      } catch (error) {
        setError(
          'There was a problem finding this user. Please try again. If the problem persists, contact support.'
        )
      }
    }

    if (location.state != null) {
      fetchData()
    }
  }, [location.state])

  if (data === []) {
    setIsDisabled(true)
  }

  return (
    <>
      <Container className='mt-3 mb-5'>
        <Row className='justify-content-center py-3'>
          <Col className='col-md-5 justify-content-center align-items-center'>
            <div className='d-flex align-items-center'>
              <h2 style={{ paddingTop: '1rem' }}>Global Entry Text Alerts</h2>
            </div>
            <h5 className='text-secondary'>
              Receive a text message alert the moment a new Global Entry
              appointment becomes available at any of the enrollment locations
              you selected.
            </h5>

            <div className='mt-4'>
              <h5 className='mb-3'>
                <u>Your Enrollment Locations:</u>
              </h5>
              <ul className='list-group'>
                {data &&
                  data.map((location) => {
                    if (location !== 'n/a') {
                      return (
                        <li
                          className='list-group-item'
                          style={{ zIndex: -1 }}
                          key={location}
                        >
                          {location}
                        </li>
                      )
                    }
                    return null // exclude the location with the value "n/a"
                  })}
              </ul>
            </div>
            <p className='text-secondary mt-1'>
              Need to edit your enrollment location choices?{' '}
              <Link to='/signup'>Click here to go back</Link>
            </p>

            <h5 className='mt-2 mb-4'>
              {/* Price: <span className='text-primary'>$30.00</span> */}
              Price: <span className='text-primary'>$25.00</span>
            </h5>
          </Col>
          <Col
            className='col-md-5 pt-3 pb-3 justify-content-center align-items-center'
            style={{
              minWidth: '355px',
            }}
          >
            <Card style={{ borderWidth: 'medium', zIndex: '1' }}>
              <Card.Header>
                <h2>Checkout</h2>
              </Card.Header>
              {isDisabled ? (
                <div className='loadingOverlay'>
                  <ReactLoading
                    type={'spin'}
                    color={'#005288'}
                    height={'20%'}
                    width={'20%'}
                  />
                </div>
              ) : null}
              <Card.Body>
                <PaymentForm
                  // applicationId='sandbox-sq0idb-oTZ7tjCRE_P9N3f4S73XTg'
                  applicationId='sq0idp-h809KaSx4-Ra140EpWTNDw'
                  createPaymentRequest={() => ({
                    countryCode: 'US',
                    currencyCode: 'USD',
                    total: {
                      amount: '25.00',
                      label: 'Total',
                    },
                    requestBillingContact: false,
                    requestShippingContact: false,
                  })}
                  cardTokenizeResponseReceived={async (token, nonce) => {
                    setIsDisabled(true)
                    const response = await fetch(
                      `${URL}/api/protected/payment`,
                      {
                        method: 'POST',
                        headers: {
                          'Content-type': 'application/json',
                          Authorization: `Bearer ${tokenRef.current}`,
                        },
                        body: JSON.stringify({
                          sourceId: token.token,
                        }),
                      }
                    )
                    const json = await response.json()

                    if (!response.ok) {
                      setError(json.error)
                      setIsDisabled(false)
                    }
                    if (response.ok) {
                      setError(null)
                      setIsDisabled(true)
                      navigate('/paymentSuccess', {
                        state: { token: json['token'] },
                      })
                    }
                  }}
                  locationId='LVSDK27WXKWMQ' // This is the main locationId for my address
                  // locationId='L6457ZWF330H9' // Sandbox locationId
                >
                  {error && (
                    <>
                      <div className='card text-white bg-danger mb-3'>
                        <div className='card-body'>
                          <h4 className='card-title'>Oh no!</h4>
                          <p className='card-text'>{error}</p>
                        </div>
                      </div>
                    </>
                  )}

                  <GooglePay style={{ marginBottom: '1rem', height: '48px' }} />

                  <ApplePay style={{ marginBottom: '1rem' }} />

                  <Row className='px-3 pb-3'>
                    <Col
                      style={{
                        whiteSpace: 'nowrap',
                        padding: '0% 0% 0% 0%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <div
                        style={{
                          borderBottom: '1.5px solid black',
                          width: '100%',
                          margin: '7% 20% 0% 0%',
                        }}
                      ></div>
                    </Col>
                    <Col
                      style={{
                        whiteSpace: 'nowrap',
                        padding: '0% 2% 0% 0%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <p style={{ textAlign: 'center', marginBottom: '0%' }}>
                        or pay with
                      </p>
                    </Col>
                    <Col
                      style={{
                        whiteSpace: 'nowrap',
                        padding: '0% 0.5% 0% 0%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <VisaSvg className='credit-card-icon' />
                    </Col>
                    <Col
                      style={{
                        whiteSpace: 'nowrap',
                        padding: '0% 0.5% 0% 0.5%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <MastercardSvg className='credit-card-icon' />
                    </Col>
                    <Col
                      style={{
                        whiteSpace: 'nowrap',
                        padding: '0% 0.5% 0% 0.5%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <AmexSvg className='credit-card-icon' />
                    </Col>
                    <Col
                      style={{
                        whiteSpace: 'nowrap',
                        padding: '0% 0.5% 0% 0.5%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <DiscoverSvg className='credit-card-icon' />
                    </Col>
                    <Col
                      style={{
                        whiteSpace: 'nowrap',
                        padding: '0% 0% 0% 0%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <div
                        style={{
                          borderBottom: '1.5px solid black',
                          width: '100%',
                          margin: '7% 0% 0% 20%',
                        }}
                      ></div>
                    </Col>
                  </Row>

                  <CreditCard
                    includeInputLabels
                    buttonProps={{
                      // isLoading makes the button disabled
                      isLoading: isDisabled,
                      onClick: () => setIsDisabled(true),
                    }}
                  >
                    Pay $25.00
                  </CreditCard>
                </PaymentForm>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default SquarePayment
