import Title from '../components/Title'
import HowItWorks from '../components/HowItWorks'
import Roadmap from '../components/Roadmap'
import WhatYouGet from '../components/WhatYouGet'
import { useEffect } from 'react'

const Home = () => {
  // Used to scroll back to the top anytime Home is called
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <main className='py-1 px-3 mb-5'>
        <Title />
        <HowItWorks />
        <Roadmap />
        <WhatYouGet />
      </main>
    </>
  )
}

export default Home
