import { Container, Row, Col } from 'react-bootstrap'
import { useEffect } from 'react'
import { ReactComponent as SendEmailSvg } from '../images/sendEmail.svg'
import { Link } from 'react-router-dom'

const MoneyBack = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Container className='px-4 pt-3' style={{ width: '80%' }}>
        <body>
          <header style={{ textAlign: 'center' }}>
            <h1>
              <b>100% Money Back Guarantee</b>
            </h1>
          </header>
          <Row>
            <Col>
              <SendEmailSvg />
            </Col>
            <Col>
              <h5 style={{ marginTop: '1rem' }}>
                If you're not satisfied with our service, contact us within 30
                days for a full refund, no questions asked.
              </h5>
              <h5 style={{ marginTop: '1rem' }}>
                <b>Email us at </b>
                <a href='mailto:support@globalentrytextalerts.com'>
                  support@globalentrytextalerts.com
                </a>
              </h5>
              <h6 style={{ marginTop: '1rem' }}>
                We stand behind our service and want you to be happy with your
                purchase.
              </h6>
              <Link to='/'>
                <button
                  type='button'
                  className='btn btn-info btn-md'
                  style={{ marginTop: '1rem', alignItems: 'center' }}
                >
                  Return Home
                </button>
              </Link>
            </Col>
          </Row>
        </body>
      </Container>
    </>
  )
}

export default MoneyBack
