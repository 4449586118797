import { Container } from 'react-bootstrap'
import { useEffect } from 'react'

const Attributes = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Container>
        <table className='table table-hover px-4'>
          <thead>
            <tr>
              <th scope='col'>Item:</th>
              <th scope='col'>Location:</th>
              <th scope='col'>Attribution:</th>
            </tr>
          </thead>
          <tbody>
            <tr className='table-active'>
              <td>textAlert.svg</td>
              <td>First picture on the home page</td>
              <td>
                <a
                  href='https://storyset.com/online'
                  className='attributes-link-style'
                >
                  Online illustrations by Storyset
                </a>
                , <br />
                <i>Additions: </i>
                <a
                  href='https://www.linkedin.com/in/breeaart/'
                  className='attributes-link-style'
                >
                  Britta (Bree) Anderson
                </a>
              </td>
            </tr>
            <tr>
              <td>signUp.svg</td>
              <td>Step 1 under "How it Works" on the home page</td>
              <td>
                <a
                  href='https://storyset.com/user'
                  className='attributes-link-style'
                >
                  User illustrations by Storyset
                </a>
              </td>
            </tr>
            <tr className='table-active'>
              <td>receiveText.svg</td>
              <td>Step 2 under "How it Works" on the home page</td>
              <td>
                <a
                  href='https://storyset.com/online'
                  className='attributes-link-style'
                >
                  Online illustrations by Storyset
                </a>
              </td>
            </tr>
            <tr>
              <td>calendar.svg</td>
              <td>Step 3 under "How it Works" on the home page</td>
              <td>
                <a
                  href='https://storyset.com/event'
                  className='attributes-link-style'
                >
                  Event illustrations by Storyset
                </a>
              </td>
            </tr>
            <tr className='table-active'>
              <td>queue.svg</td>
              <td>People waiting in queue image on home page</td>
              <td>
                <a
                  href='https://storyset.com/people'
                  className='attributes-link-style'
                >
                  People illustrations by Storyset
                </a>
              </td>
            </tr>
            <tr>
              <td>cancelled.svg</td>
              <td>Tablet with word "cancelled" on the home page</td>
              <td>
                <a
                  href='https://storyset.com/app'
                  className='attributes-link-style'
                >
                  App illustrations by Storyset
                </a>
              </td>
            </tr>
            <tr className='table-active'>
              <td>runningMan.svg</td>
              <td>Male running with speedometer image on home page</td>
              <td>
                <a
                  href='https://storyset.com/web'
                  className='attributes-link-style'
                >
                  Web illustrations by Storyset
                </a>
              </td>
            </tr>
            <tr>
              <td>error.svg</td>
              <td>Robot 404 error image on error page</td>
              <td>
                <a
                  href='https://storyset.com/web'
                  className='attributes-link-style'
                >
                  Web illustrations by Storyset
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </Container>
    </>
  )
}

export default Attributes
