import { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import SquarePayment from '../components/SquarePayment'

const Payment = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Container className='px-4'>
        <SquarePayment />
      </Container>
    </>
  )
}

export default Payment
