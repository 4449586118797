import React from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const Header = () => {
  return (
    <header>
      <Navbar
        bg='primary'
        expand='lg'
        variant='dark'
        className='position-fixed w-100'
        collapseOnSelect='true'
        style={{ zIndex: '999' }}
      >
        <Container>
          <LinkContainer to='/'>
            <Navbar.Brand>
              <i className='fa-solid fa-plane-circle-check'></i>
              &nbsp;&nbsp;&nbsp;Global Entry Text Alerts
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ms-auto'>
              <LinkContainer to='/' style={{ whiteSpace: 'nowrap' }}>
                <Nav.Link>
                  <i
                    className='fa-solid fa-house'
                    style={{ paddingRight: '7%' }}
                  ></i>
                  Home
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to='/signUp' style={{ whiteSpace: 'nowrap' }}>
                <Nav.Link>
                  <i
                    className='fa-solid fa-user'
                    style={{ paddingRight: '7%' }}
                  ></i>
                  Sign Up
                </Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header
